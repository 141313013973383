$(document).ready(function () {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const navigateToWow = urlParams.get('wow')
  const navigateToStories = urlParams.get('stories')
  const navigateToHealthcare = urlParams.get('healthcare')


  $("#form").css("display", "none");
  $("html").css("overflow-y", "unset");

  const quotes = new URL("../assets/Quotes.svg", import.meta.url);
  const whiteArrow = new URL("../assets/WhiteArrow.svg", import.meta.url);

  var steps = 6;

  var toggle = document.querySelector('.nav-toggle');

  toggle.addEventListener('click', function (e) {
    this.classList.toggle('opened');

    if (this.classList.contains('opened')) {
      $(".navigation-mobile-content").css("visibility", "visible");
      $(".navigation-mobile-content").css("opacity", "1");
    }
    else {

      $(".navigation-mobile-content").css("visibility", "hidden");
      $(".navigation-mobile-content").css("opacity", "0");
    }
  });

  function getRandomInt(max) {
    return Math.floor(Math.random() * max);
  }

  // const element = document.getElementById("HOME");
  // element.scrollIntoView({ behavior: "smooth" });

  gsap.fromTo(".home-line-up", { opacity: 0 }, {
    opacity: 1, duration: 4, delay: 0.5, ease: Power1.easeOut,
    scrollTrigger: {
      trigger: ".home-line-up",
      toggleActions: 'restart pause restart pause'
    }
  });
  gsap.fromTo(".home-main-title", { opacity: 0 }, {
    opacity: 1, duration: 4, delay: 1.5, ease: Power1.easeOut, scrollTrigger: {
      trigger: ".home-main-title",
      toggleActions: 'restart pause restart pause'
    }
  });
  $(".loader-container").css("display", "flex");

  updateActiveItem("HOME", ".home-menu-item", 0);

  fetch('https://classicimage.com/newclassicimagewordpress/wp-json/wp/v2/pages/69').then(function (response) {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(response);
    }
  }).then(function (data) {

    if (data.title.rendered.includes("Home Videos")) {
      var videoContent = data.content.rendered;
      var videoHtmlContent = $.parseHTML(videoContent);
      var temp1 = videoHtmlContent.filter(elem => elem.nodeName === "DIV")[0].children;
      var videos = Array.from(temp1).filter(elem => elem.nodeName === "NOSCRIPT")[0].children[0].children;
      var videoIndex = getRandomInt(videos.length);
      var videoHref = videos[videoIndex].children[0].href;
      $("#HomeVideoSource").attr("src", videoHref);
      $("#HomeVideoContent video")[0].load();
      $("#HomeVideoContent video")[0].play();
      $(".loader-container").css("display", "none");
    }
  })

  fetch('https://classicimage.com/newclassicimagewordpress/wp-json/wp/v2/pages').then(function (response) {
    if (response.ok) {
      return response.json();
    } else {
      return Promise.reject(response);
    }
  }).then(function (data) {
    if (!data) {
      return;
    }
    for (let i = 0; i < data.length; i++) {
      if (data[i].title.rendered.includes("Sample Page")) {
        continue;
      }
      if (data[i].title.rendered.includes("Story_Case_Template")) {
        continue;
      }
      if (data[i].title.rendered.includes("Home Videos")) {
        continue;
      }
      var content = data[i].content.rendered;
      var link = data[i].link + "?source=wow";
      var htmlContent = $.parseHTML(content);
      var projectNumber = $(htmlContent).find(".project-number").text();
      var projectLocation = $(htmlContent).find(".project-location").text();
      var projectTitle = data[i].title.rendered;
      var projectImg = $(htmlContent).find(".project-img")[0].src;

      var cardCreation = '<div class="swiper-slide" onclick="goToLinkWithotNewTab(' + "'" + link + "'" + ')">'
      cardCreation += '<div class="image-content">'
      cardCreation += '<img src="' + projectImg + '" alt=""/>'
      cardCreation += '</div>'
      cardCreation += '<div class="card-content">'
      cardCreation += '<h2 class="name">' + projectTitle + '</h2>'
      cardCreation += '<p class="description">' + projectLocation + '</p>'
      cardCreation += '<div class="number"><h5>' + projectNumber + '</h5><div class="line"></div></div>'
      cardCreation += '</div>'
      cardCreation += '</div>'
      cardCreation += '</div>'

      var storySmallText = $(htmlContent).find(".testimonial-small-text").text();
      var storyLargeText = $(htmlContent).find(".testimonial-large-text").text();
      var storyAuthor = $(htmlContent).find(".testimonial-author").text();
      var storyFunction = $(htmlContent).find(".testimonial-function").text();
      var storyImgElement = $(htmlContent).find(".testimonial-img")[0];
      var storyImg;
      if(storyImgElement) {
        storyImg = $(htmlContent).find(".testimonial-img")[0].src;
      }

      link = data[i].link + "?source=stories";

      if(storySmallText && storyLargeText) {
        var story = '<div class="swiper-slide">'
        story += '<div class="story-content">'
        story += '<h5 class="story-subtext ">' + storySmallText + '</h5>'
        story += '<hr class="bg-primary border-2 border-top " style="width:10vw;">'
        story += '<h1 class="story-text">' + storyLargeText + '</h1>'
        story += '<div class="story-signature">'
        story += '<div class="story-signature-left-line"></div>'
        story += '<div class="story-img"><img src="' + storyImg + '"/></div>'
        story += '<div class="story-writer">'
        story += '<div class="story-quotes">'
        story += '<img src="' + quotes + '"/>'
        story += '<div class="story-signature-right-line"></div>'
        story += '</div>'
        story += '<p class="story-author">' + storyAuthor + '</p>'
        story += '<p class="story-function">' + storyFunction + '</p>'
        story += '<div class="story-view-case" onclick="goToLinkWithotNewTab(' + "'" + link + "'" + ')">'
        story += '<p class="story-view-text">View case study</p>'
        story += '<img src="' + whiteArrow + '"/>'
        story += '</div>'
        story += '</div>'
        story += '</div>'
        story += '</div>'

        $(".swiper1 .swiper-wrapper").append($(story));

        new Swiper(".swiper1", {
          slidesPerView: 1,
          spaceBetween: 30,
          speed: 1000,
          centeredSlides: true,
          loop: true,
          centerSlide: true,
          grabCursor: false,
          autoplay: {
            delay: 2500,
            disableOnInteraction: true,
          },
          pagination: {
            el: '.swiper-pagination1',
            clickable: true,
          },
  
        });
      }

      $(".swiper2 .swiper-wrapper").append($(cardCreation));


      gsap.fromTo(".cloud1", { x: -500 }, {
        x: -200, duration: 4,
        scrollTrigger: {
          trigger: ".cloud1",
          toggleActions: 'restart pause restart pause'
        }
      });
      gsap.fromTo(".cloud2", { x: 700 }, {
        x: 0, duration: 4,
        scrollTrigger: {
          trigger: ".cloud2",
          toggleActions: 'restart pause restart pause'
        }
      });
      gsap.fromTo(".cloud3", { x: 500 }, {
        x: 400, duration: 4, scrollTrigger: {
          trigger: ".cloud3",
          toggleActions: 'restart pause restart pause'
        }
      });

      gsap.fromTo(".purpose-content", { opacity: 0 }, {
        opacity: 1, duration: 4, scrollTrigger: {
          trigger: ".purpose-content",
          toggleActions: 'restart pause restart pause'
        }
      });



    }
  })


  $(".our-founder").on("click", function () {

    gsap.to(window, { scrollTo: '#MIND', ease: 'Power1.easeInOut' })

  });

  gsap.fromTo(".scroll-up-pin", { y: -16 }, { duration: 1, y: 10, ease: "circ", repeat: -1, yoyo: true })
  gsap.fromTo(".scroll-down-pin", { y: 16 }, { duration: 1, y: -10, ease: "circ", repeat: -1, yoyo: true })

  $(".go-classic-btn").on("click", function () {

    $(".form-container").css('display', 'flex');
    $(".form-submitted").css('display', 'none');
    $("#form").css('background', '#F9F5EF');
    $("#form").css("display", "block");
    $("html").css("overflow-y", "hidden");

    //window.location.replace("form.html");

  });

  gsap.fromTo("#DownloadIcon", { y: -8 }, { duration: 1, y: 8, ease: "circ.in", repeat: -1, yoyo: true })

  gsap.fromTo(".drop-pin", { y: -16 }, { duration: 0.8, y: 16, ease: "circ.in", repeat: -1, yoyo: true })


  $("#ScrollUpButton").on("click", function (e) {
    const element = document.getElementById("HOME");
    element.scrollIntoView({ behavior: "smooth" });
    updateActiveItem("HOME", ".home-menu-item", 0);
  });

  $("#ScrollDownButton").on("click", function (e) {
    const element = document.getElementById("HOME");
    element.scrollIntoView({ behavior: "smooth" });
    updateActiveItem("HOME", ".home-menu-item", 0);
  });

  const scrollIntoViewWithOffset = (element, offset) => {
    window.scrollTo({
      behavior: 'smooth',
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        offset,
    })
  }

  $(".mobile-menu-item").on("click", function (e) {


    toggle.classList.toggle('opened');
    $(".navigation-mobile-content").css("visibility", "hidden");
    $(".navigation-mobile-content").css("opacity", "0");

    const element = document.getElementById(e.target.innerHTML);
    scrollIntoViewWithOffset(element, 80);

  });

  $(".menu-item").on("click", function (e) {

    const element = document.getElementById(e.target.innerHTML);
    element.scrollIntoView({ behavior: "smooth" });
  });

  function map(valueToMap, in_min, in_max, out_min, out_max) {
    return (valueToMap - in_min) * (out_max - out_min) / (in_max - in_min) + out_min;
  }

  var homeVideoContent = document.getElementById("HomeVideoContent");

  window.addEventListener("scroll", (event) => {

    homeVideoContent.style.opacity = 1 - +window.pageYOffset / 700 + '';
    homeVideoContent.style.top = +window.pageYOffset + 'px';
    homeVideoContent.style.backgroundPositionY = - +window.pageYOffset / 2 + 'px';
    updateActiveItem("HOME", ".home-menu-item", 0);
    updateActiveItem("STORIES", ".stories-menu-item", 1);
    updateActiveItem("OUR WOW", ".wow-menu-item", 2);
    updateActiveItem("PURPOSE", ".purpose-menu-item", 3);
    updateActiveItem("DESIGN", ".design-menu-item", 4);
    updateActiveItem("HEALTHCARE", ".healthcare-menu-item", 5);
    updateActiveItem("ARCHITECTS", ".architects-menu-item", 6);
    updateActiveItem("VALUES", ".values-menu-item", 7);
    updateActiveItem("STEPS", ".steps-menu-item", 8);
    updateActiveItem("PHILOSOPHY", ".philosophy-menu-item", 9);
    updateActiveItem("PROCESS", ".process-menu-item", 10);
    updateActiveItem("MIND", ".mind-menu-item", 11);
    updateActiveItem("CONNECT", ".connect-menu-item", 12);


  });

  function updateActiveItem(blockID, blockMenuItem, blockIndex) {
    const block = document.getElementById(blockID);
    if (isInViewport(block)) {
      $(blockMenuItem).addClass("active-menu-item");
      updateNav(blockIndex, block);
    } else {
      $(blockMenuItem).removeClass("active-menu-item");
    }
  }

  function updateNav(blockIndex, block) {
    const rect = block.getBoundingClientRect();
    var stepMarginTop = Number($(".step").css("margin-top").replace("px", ""));
    var stepHeight = $(".step")[0].getBoundingClientRect().height;
    var initialTop = stepMarginTop + stepHeight / 2;
    var minTop = blockIndex * steps * (stepMarginTop + stepHeight) + initialTop;
    var maxTop = (blockIndex + 1) * steps * (stepMarginTop + stepHeight) + initialTop;
    var newTop = map((-1 * rect.top), rect.x, rect.x + rect.height, minTop, maxTop);
    $(".timeline-cursor").css("top", newTop + "px");
  }

  function isInViewport(element) {
    const rect = element.getBoundingClientRect();
    return (
      rect.top <= 10 &&
      rect.top > -1 * rect.height + 10
    );
  }

  setTimeout(function() {
    if(navigateToWow === "true") {
      const element = document.getElementById("OUR WOW");
      element.scrollIntoView({ behavior: "smooth" });
    }
    else if(navigateToStories === "true") {
      const element = document.getElementById("STORIES");
      element.scrollIntoView({ behavior: "smooth" });
    }
    else if(navigateToHealthcare === "true") {
      const element = document.getElementById("HEALTHCARE");
      element.scrollIntoView({ behavior: "smooth" });
    }
  }, 1000);

});